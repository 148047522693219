import React, { useState, useEffect } from 'react';
import {
  Col,
  Row,
  Table,
  Select,
  Spin,
  Modal,
  Button,
  Tooltip,
  Input,
  Breadcrumb,
  Switch,
} from 'antd';
import { DownloadOutlined, HomeOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import moment from 'moment';
import styles from './style.module.scss';
import { api } from '../../services/apiClient';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const { Option } = Select;

const Consultas = () => {
  const [loading, setLoading] = useState(true);
  const [clientes, setClientes] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [size, setSize] = useState('large');
  const [secondModalOpen, setSecondModalOpen] = useState(false);
  const [selectedCliente, setSelectedCliente] = useState(null);
  const [previewFileUrl, setPreviewFileUrl] = useState('');

  // Auxiliar para formatar a data apenas no modal ou onde for necessário
  const formatIfValid = (dateStr) => {
    if (!dateStr) return '';
    const dateObj = moment(dateStr, 'DD/MM/YYYY', true); 
    return dateObj.isValid() ? dateObj.format('DD/MM/YYYY') : dateStr;
  };

  useEffect(() => {
    const fetchClientes = async () => {
      try {
        const response = await api.get('/clientes');
        setClientes(response.data);
        setLoading(false);
      } catch (error) {
        toast.error('Erro ao buscar clientes.');
        setLoading(false);
      }
    };

    fetchClientes();
  }, []);

  const handleEtapaChange = async (value, record) => {
    try {
      const updatedClientes = clientes.map((cliente) =>
        cliente.id === record.id ? { ...cliente, etapa: value } : cliente
      );
      setClientes(updatedClientes);

      await api.put(`/clientes/${record.id}`, { etapa: value });
      toast.success('Etapa atualizada com sucesso.');
    } catch (error) {
      toast.error('Erro ao atualizar etapa.');
    }
  };

  const handleInputDateChange = async (e, record) => {
    const typed = e.target.value; // O que o usuário digitou (ex: "12/02/2025" ou "12/02")

    // Atualiza local (independente de estar válido ou não, para o usuário ver o que digitou)
    const updatedClientes = clientes.map((cliente) =>
      cliente.id === record.id ? { ...cliente, dataConsulta: typed } : cliente
    );
    setClientes(updatedClientes);

    // Tentamos validar (DD/MM/YYYY estrito)
    if (!typed) {
      // Se estiver vazio, simplesmente salva vazio no banco
      try {
        await api.put(`/clientes/${record.id}`, { dataConsulta: '' });
        toast.success('Data da consulta atualizada (removida) com sucesso.');
      } catch (error) {
        toast.error('Erro ao atualizar data da consulta.');
      }
      return;
    }

    const dateObj = moment(typed, 'DD/MM/YYYY', true);
    if (!dateObj.isValid()) {
      // Se for inválido, não chamamos a API com esse valor
      // toast.error('Data inválida. Use o formato DD/MM/YYYY.');
      return;
    }

    // Se passou na validação, então pode chamar a API e salvar
    try {
      await api.put(`/clientes/${record.id}`, { dataConsulta: typed });
      toast.success('Data da consulta atualizada com sucesso.');
    } catch (error) {
      toast.error('Erro ao atualizar data da consulta.');
    }
  };

  const handlePresencialChange = async (checked, record) => {
    try {
      const updatedClientes = clientes.map((cliente) =>
        cliente.id === record.id ? { ...cliente, isPresencial: checked } : cliente
      );
      setClientes(updatedClientes);

      await api.put(`/clientes/${record.id}`, { isPresencial: checked });
      toast.success('Status de atendimento presencial atualizado com sucesso.');
    } catch (error) {
      toast.error('Erro ao atualizar status de atendimento presencial.');
    }
  };

  const handlePreview = (url) => {
    window.open(url, '_blank');
  };

  const handleDownloadClick = (record) => {
    setSelectedCliente(record);
    setSecondModalOpen(true);
  };

  const generatePDF = () => {
    const modalContent = document.getElementById('second-modal-content');
    html2canvas(modalContent).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg', 1.0);
      const pdf = new jsPDF('p', 'mm', 'a4');
      pdf.addImage(imgData, 'JPEG', 10, 10, 190, 120);
      pdf.save('cliente.pdf');
    });
  };

  const columns = [
    {
      title: 'Nome Completo',
      dataIndex: 'nomeCliente',
      key: 'nomeCliente',
      className: styles.columnEllipsis,
    },
    {
      title: 'Etapa',
      key: 'etapa',
      dataIndex: 'etapa',
      render: (_, record) => (
        <Select
          defaultValue={record.etapa}
          style={{ width: 120 }}
          onChange={(value) => handleEtapaChange(value, record)}
        >
          <Option value='contato'>Contato</Option>
          <Option value='negociacao'>Negociação</Option>
          <Option value='fechado'>Fechado</Option>
        </Select>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      className: styles.columnEllipsis,
    },
    {
      title: 'Presencial',
      dataIndex: 'isPresencial',
      key: 'isPresencial',
      render: (_, record) => (
        <Switch
          checked={record.isPresencial}
          onChange={(checked) => handlePresencialChange(checked, record)}
        />
      ),
    },
    {
      title: 'Consulta data',
      dataIndex: 'dataConsulta',
      key: 'dataConsulta',
      width: 300,
      className: styles.columnDate,
      render: (_, record) => (
        <Input
          style={{ width: 130 }}
          placeholder='DD/MM/YYYY'
          // Mostramos exatamente o que vem do state, sem reformatar
          value={record.dataConsulta || ''}
          onChange={(e) => handleInputDateChange(e, record)}
        />
      ),
    },
    {
      title: 'Endereço',
      dataIndex: 'enderecoCompleto',
      key: 'enderecoCompleto',
      render: (_, record) => {
        const enderecoCompleto = `${record.logradouro}, ${record.numero}, ${record.bairro}, ${record.cidade}, ${record.estado}, ${record.cep}`;
        return (
          <Tooltip title={enderecoCompleto}>
            <span className={styles.truncatedText}>{enderecoCompleto}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Telefone',
      dataIndex: 'telefone',
      key: 'telefone',
      className: styles.columnEllipsis,
    },
    {
      title: 'CPF/CNPJ',
      dataIndex: 'cpfCnpj',
      key: 'cpfCnpj',
      className: styles.columnEllipsis,
    },
    {
      title: 'Descrição',
      dataIndex: 'assuntoConsulta',
      key: 'assuntoConsulta',
      className: styles.columnEllipsis,
    },
    {
      title: 'Visualizar Documento',
      key: 'visualizarDocumento',
      render: (_, record) =>
        record.urlDocumentos &&
        record.urlDocumentos.length > 0 && (
          <Button
            onClick={() =>
              handlePreview(`https://credx-bucket.s3.amazonaws.com/${record.urlDocumentos[0]}`)
            }
            type='primary'
          >
            Visualizar
          </Button>
        ),
    },
    {
      title: 'Download',
      key: 'downloadDocumento',
      render: (_, record) => (
        <Button
          type='primary'
          icon={<DownloadOutlined />}
          size={size}
          onClick={() => handleDownloadClick(record)}
        />
      ),
    },
  ];

  return (
    <div>
      <Breadcrumb
        items={[
          {
            href: '',
            title: <HomeOutlined />,
          },
          {
            href: '',
            title: <span>Agendamentos</span>,
          },
          {
            title: 'Consulta',
          },
        ]}
      />
      <div className={`${styles.container} ${styles.tableContainer}`}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Spin spinning={loading}>
              <Table dataSource={clientes} columns={columns} rowKey='id' />
            </Spin>
          </Col>
        </Row>

        <Modal
          visible={isModalOpen}
          title='Visualizar Imagem'
          footer={null}
          onCancel={() => setIsModalOpen(false)}
          width={800}
        >
          {previewFileUrl && <img alt='documento' style={{ width: '100%' }} src={previewFileUrl} />}
        </Modal>

        <Modal
          visible={secondModalOpen}
          title='Resumo das Informações'
          onCancel={() => setSecondModalOpen(false)}
          footer={[
            <Button key='back' onClick={() => setSecondModalOpen(false)}>
              Fechar
            </Button>,
            <Button key='generatePDF' type='primary' onClick={generatePDF}>
              Gerar PDF
            </Button>,
          ]}
        >
          {selectedCliente && (
            <div id='second-modal-content'>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <label>Nome Completo:</label>
                  <Input value={selectedCliente.nomeCliente} readOnly />
                </Col>
                <Col span={12}>
                  <label>CPF/CNPJ:</label>
                  <Input value={selectedCliente.cpfCnpj} readOnly />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <label>Email:</label>
                  <Input value={selectedCliente.email} readOnly />
                </Col>
                <Col span={12}>
                  <label>Telefone:</label>
                  <Input value={selectedCliente.telefone} readOnly />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <label>Presencial:</label>
                  <Input value={selectedCliente.isPresencial ? 'Sim' : 'Não'} readOnly />
                </Col>
                <Col span={12}>
                  <label>Etapa:</label>
                  <Input value={selectedCliente.etapa} readOnly />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <label>Data de Nascimento:</label>
                  <Input
                    value={
                      selectedCliente.dataNascimento
                        ? formatIfValid(selectedCliente.dataNascimento)
                        : ''
                    }
                    readOnly
                  />
                </Col>
                <Col span={12}>
                  <label>Data da Consulta:</label>
                  <Input
                    value={
                      selectedCliente.dataConsulta
                        ? formatIfValid(selectedCliente.dataConsulta)
                        : ''
                    }
                    readOnly
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <label>Logradouro:</label>
                  <Input value={selectedCliente.logradouro} readOnly />
                </Col>
                <Col span={5}>
                  <label>N°:</label>
                  <Input value={selectedCliente.numero} readOnly />
                </Col>
                <Col span={7}>
                  <label>CEP:</label>
                  <Input value={selectedCliente.cep} readOnly />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <label>Bairro:</label>
                  <Input value={selectedCliente.bairro} readOnly />
                </Col>
                <Col span={12}>
                  <label>Cidade/UF:</label>
                  <Input value={`${selectedCliente.cidade}/${selectedCliente.estado}`} readOnly />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <label>Assunto:</label>
                  <Input value={selectedCliente.assuntoConsulta} readOnly />
                </Col>
                <Col span={12}>
                  <label>Ar-condicionado Ligado:</label>
                  <Input value={selectedCliente.arCondicionadoLigado ? 'Sim' : 'Não'} readOnly />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                {selectedCliente.arCondicionadoLigado && (
                  <Col span={12}>
                    <label>Temperatura do Ar:</label>
                    <Input value={selectedCliente.temperaturaAr} readOnly />
                  </Col>
                )}
                <Col span={12}>
                  <label>Preferência de Água:</label>
                  <Input value={selectedCliente.aguaPreferencia ?? ''} readOnly />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <label>Café:</label>
                  <Input value={selectedCliente.cafe ? 'Sim' : 'Não'} readOnly />
                </Col>
                <Col span={12}>
                  <label>Refrigerante Preferido:</label>
                  <Input value={selectedCliente.refrigerantePreferencia ?? ''} readOnly />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <label>Suco/Chá:</label>
                  <Input value={selectedCliente.sucoOuChasPreferencia ?? ''} readOnly />
                </Col>
                <Col span={12}>
                  <label>Comida Preferida:</label>
                  <Input value={selectedCliente.comidaPreferencia ?? ''} readOnly />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <label>Deseja Gravar a Consulta:</label>
                  <Input value={selectedCliente.gravarConsulta ? 'Sim' : 'Não'} readOnly />
                </Col>
                <Col span={12}>
                  <label>Documentos Relacionados:</label>
                  <Input
                    value={
                      selectedCliente.documentosRelacionados?.length > 0
                        ? 'Sim'
                        : 'Não'
                    }
                    readOnly
                  />
                </Col>
              </Row>
            </div>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default Consultas;
