// src/pages/CheckListDashboard/CheckListDashboard.jsx
import React, { useState, useEffect } from 'react';
import { Table, Spin, Modal, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { api } from '../../services/apiClient'; // Ajuste o caminho para seu apiClient
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import styles from './style.module.scss';

// Exemplo de perguntas, caso você precise “casar” as respostas com cada pergunta
const questionsList = [
  'Na data da integralização do capital social, os valores integralizados possuíam origem lícita e comprovada para a constituição da empresa, ou seja, existia lastro dos valores integralizados?',
  'A estrutura atual da empresa é compatível com o volume de operação e atividades desenvolvidas?',
  'A empresa vem registrando regularmente seu faturamento ou receita bruta, além de vendas de mercadorias ou prestação de serviços de forma compatível com a emissão de notas de entrada e saída de valores em sua conta corrente e contabilidade?',
  'Há compatibilidade em relação aos valores movimentados em conta corrente bancária e valores lançados na contabilidade?',
  'Há procuração para terceiros operarem as contas correntes da empresa?',
  'A empresa já passou por algum procedimento especial de fiscalização aduaneira ou canal cinza? Sofreu alguma penalidade aduaneira como por exemplo multa aduaneira ou perdimento?',
  'Considerando que é necessário provar a origem lícita dos recursos empregados nas importações, há registros e documentos comprobatórios da origem lícita dos recursos utilizados nas operação de importação?',
  'Existem responsáveis pela negociação de valores das mercadorias importadas e registro dos meios pelos quais foram feitas as negociações (e-mail, WhatsApp, WeChat, telefone, entre outros) que comprovem a efetiva negociação, qualidade do produto, quantidade do produto bem como tabela de preços e valor das mercadorias importadas?',
  'Houve contrato de câmbio e sua respectiva liquidação? Em caso negativo, há prova de prazo para pagamento?',
  'Houve contrato de seguro da mercadoria?',
  'Sobre a contratação de intermediação na importação foi contratada a prestação de serviços de despachante aduaneiro para a intermediação e conclusão da importação?',
  'É feita uma checagem prévia acerca do eventual registro de marca referente à mercadoria importada?',
  'São tomados cuidados mínimos para auferir a regularidade do comprador no mercado interno das mercadorias importadas, tais como se o comprador existe de fato e de direito e se existem registros da negociação e venda das mercadorias bem como recebimento dos valores pelas mercadorias negociadas?',
  'Existe margem de lucro razoável e compatível com o mercado das mercadorias importadas e vendidas no mercado interno?',
  'As importações realizadas estão em conformidade com as áreas de atuação registradas no contrato social e Cnae da empresa?',

];

export default function CheckListDashboard() {
  const [loading, setLoading] = useState(true);
  const [checklists, setChecklists] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedChecklist, setSelectedChecklist] = useState(null);

  // Busca todos os checklists do back-end
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.get('/checklist');
        setChecklists(response.data);
      } catch (error) {
        toast.error('Erro ao buscar dados do checklist.');
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  // Abre o modal e seta o checklist selecionado
  const handleViewDetails = (record) => {
    setSelectedChecklist(record);
    setModalOpen(true);
  };

  // Fecha o modal
  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedChecklist(null);
  };

  // Gera o PDF (similar ao que foi feito no DashboardCompliance)
  const handleGeneratePDF = () => {
    const modalContent = document.getElementById('checklist-modal-content');
    if (!modalContent) return;

    html2canvas(modalContent, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pageWidth = pdf.internal.pageSize.getWidth();
      // Ajuste conforme a altura total capturada
      const imgProps = pdf.getImageProperties(imgData);
      const pdfHeight = (imgProps.height * pageWidth) / imgProps.width;

      pdf.addImage(imgData, 'PNG', 0, 0, pageWidth, pdfHeight);
      pdf.save(`checklist-${selectedChecklist?.id}.pdf`);
    });
  };

  // Definindo as colunas da tabela
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '6rem',
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      className: styles.columnEllipsis,
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
      className: styles.columnEllipsis,
    },
    {
      title: 'Telefone',
      dataIndex: 'phone',
      key: 'phone',
      className: styles.columnEllipsis,
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (_, record) => (
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          onClick={() => handleViewDetails(record)}
        >
          Respostas
        </Button>
      ),
    },
  ];

  return (
    <div className={styles.container}>
      <Spin spinning={loading}>
        <Table
          dataSource={checklists}
          columns={columns}
          rowKey="id"
          pagination={{ pageSize: 8 }}
        />
      </Spin>

      <Modal
        open={modalOpen}
        title="Detalhes do Checklist"
        onCancel={handleCloseModal}
        width={800}
        footer={[
          <Button key="fechar" onClick={handleCloseModal}>
            Fechar
          </Button>,
          <Button key="pdf" type="primary" onClick={handleGeneratePDF}>
            Gerar PDF
          </Button>,
        ]}
      >
        {selectedChecklist && (
          <div id="checklist-modal-content">
            <p><strong>ID: </strong>{selectedChecklist.id}</p>
            <p><strong>Nome: </strong>{selectedChecklist.name}</p>
            <p><strong>E-mail: </strong>{selectedChecklist.email}</p>
            <p><strong>Telefone: </strong>{selectedChecklist.phone}</p>

            {/* Exemplo de exibição das respostas
                Se 'answers' for um array de strings (ex: ['Sim','Não','Sim'...]),
                vamos percorrer e associar cada pergunta do questionsList */}
            <hr />
            <h3>Respostas:</h3>
            {selectedChecklist.answers && selectedChecklist.answers.length > 0 ? (
              selectedChecklist.answers.map((answer, index) => (
                <div key={index} style={{ marginBottom: '8px' }}>
                  <strong>{questionsList[index] ?? `Pergunta ${index + 1}`}</strong>
                  <br />
                  Resposta: {answer}
                </div>
              ))
            ) : (
              <p>Nenhuma resposta registrada.</p>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
}
